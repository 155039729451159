import { defineStore } from "pinia";
import request from "@/store/request";
import { ApiMethods, ApiResponses, Types } from "patchandgo-ui";
import utils from "@/utils";

interface CatalogStoreState {
  products: (Types.ProductReduced & Types.IsInCartModifier)[];
  current: Types.Product | null;
  minMax: number[];
  total: number;
}

export const useCatalogStore = defineStore("catalogStore", {
  state: (): CatalogStoreState => {
    return {
      products: [],
      current: null,
      minMax: [0, 0],
      total: 0,
    };
  },
  actions: {
    async getProducts(params: Types.CatalogPageQueryParams, merge = false) {
      try {
        const productsData =
          await request.send<ApiResponses.GetProductsResponse>(
            "get",
            ApiMethods.GET_PRODUCTS,
            params
          );
        const products = productsData.products || [];
        const mapper = (item: Types.ProductReduced) => {
          return {
            ...item,
            inCart: utils.getCountInCart(item.id),
          };
        };

        if (merge) {
          this.products = [...this.products, ...products.map(mapper)];
        } else {
          this.products = products.map(mapper);
        }

        this.total = productsData?.total || 0;
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getProductBySlug(slug: string) {
      try {
        this.current =
          await request.send<ApiResponses.GetProductBySlugResponse>(
            "get",
            ApiMethods.GET_PRODUCT_BY_SLUG(slug)
          );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getMinMax() {
      try {
        const data = await request.send("get", "/filter/prices");
        // @ts-ignore
        this.minMax = [data.min, data.max];
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
  },
});
