interface Config {
  API_URL: string;
}

const cfg: Config = (window as any).cfg;
const configObj = {
  API_URL: cfg.API_URL,
};

export default configObj;
