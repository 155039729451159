import { Types } from "patchandgo-ui";
import { defineStore } from "pinia";
import { useUserStore } from "@/store/user.store";
import { useLoaderStore } from "@/store/loader.store";

export interface ModalContactInfoStore {
  form: Types.ProfileContactInfo;
  modalOpen: boolean;
}

export const useModalContactInfoStore = defineStore("modalContactInfo", {
  state(): ModalContactInfoStore {
    return {
      form: {
        contact_type: "personal",
      },
      modalOpen: false,
    };
  },
  actions: {
    open() {
      this.modalOpen = true;
    },
    close() {
      this.modalOpen = false;
    },
    setForm(form: Types.ProfileContactInfo) {
      this.form = form;
    },
    async save(data: Types.ProfileContactInfo) {
      const userStore = useUserStore();
      const loaderStore = useLoaderStore();
      try {
        loaderStore.setLoaderState(true);
        await userStore.updateProfile(data);
      } finally {
        loaderStore.setLoaderState(false);
        this.close();
      }
    },
  },
});
