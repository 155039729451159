import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "element-plus/dist/index.css";
import "bootstrap/dist/css/bootstrap-grid.css";
import "bootstrap/dist/css/bootstrap-reboot.css";
import "patchandgo-ui/style.css";
import "patchandgo-ui/components.css";
import { PagFilters } from "patchandgo-ui";

const pinia = createPinia();
const app = createApp(App);

pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(PagFilters.default);
app.use(store);
app.use(router);
app.use(pinia);
app.use(ElementPlus);
app.mount("#app");
