import { defineStore } from "pinia";
import request from "@/store/request";
import { ApiMethods, ApiResponses, PagCartUtils, Types } from "patchandgo-ui";
import utils from "@/utils";

interface BasketStoreState {
  basket: ApiResponses.CartGetResponse | null;
  selectedPriceIndex: number;
}

export const useBasketStore = defineStore("basketStore", {
  state: (): BasketStoreState => {
    return {
      basket: null,
      selectedPriceIndex: 0,
    };
  },
  actions: {
    async getBasket() {
      try {
        this.basket = await request.send<ApiResponses.CartGetResponse>(
          "get",
          ApiMethods.GET_CART
        );
        this.updateSelectedPriceIndex();
        PagCartUtils.handleChangedItems(this.basket);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async addProductById(product_id: number, quantity: number) {
      try {
        const formData = new FormData();
        formData.append("product_id", product_id.toString());
        formData.append("quantity", quantity.toString());
        this.basket = await request.send<ApiResponses.CartGetResponse>(
          "post",
          ApiMethods.ADD_TO_CART,
          formData
        );
        this.updateSelectedPriceIndex();
        PagCartUtils.handleChangedItems(this.basket);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async removeProductFromCartById(id: string) {
      try {
        const response = await request.send<ApiResponses.CartGetResponse>(
          "delete",
          `/cart/${id}`
        );

        this.basket = {
          items: {},
          ...response,
        };
        this.updateSelectedPriceIndex();
        PagCartUtils.handleChangedItems(this.basket);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateCartItemQuantity(id: string, quantity: number) {
      try {
        const formData = new FormData();
        formData.append("quantity", quantity.toString());
        this.basket = await request.send<ApiResponses.CartGetResponse>(
          "put",
          `/cart/${id}`,
          formData
        );
        this.updateSelectedPriceIndex();
        PagCartUtils.handleChangedItems(this.basket);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async checkout(form: Types.CheckoutForm) {
      try {
        const {
          contact_type,
          comment,
          email,
          first_name,
          last_name,
          patronymic,
          organization_name,
          address,
          crate,
          destination,
          insurance,
          tk,
          warm,
          phone,
          passport,
          inn,
          shop_url,
          contact_person,
        } = form;
        const requestData = Object.assign(
          {
            contact_type,
            comment,
            shipping: {
              address,
              crate,
              destination,
              insurance,
              tk,
              warm,
            },
          },
          contact_type === "personal"
            ? {
                contact_personal: {
                  email,
                  first_name,
                  last_name,
                  patronymic,
                  phone,
                  passport,
                },
              }
            : {
                contact_organization: {
                  email,
                  organization_name,
                  phone,
                  inn,
                  shop_url,
                  contact_person,
                },
              }
        );

        const data = await request.send<any>(
          "post",
          "/order/checkout",
          requestData
        );
        await this.getBasket();

        return data;
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async clearCart() {
      try {
        this.basket = await request.send<ApiResponses.CartGetResponse>(
          "delete",
          "/cart/"
        );
        this.updateSelectedPriceIndex();
        PagCartUtils.handleChangedItems(this.basket);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    updateSelectedPriceIndex() {
      const tiers = [30000, 50000, 80000];
      const tierIndex = tiers.findIndex(
        (tier) => tier === this.basket?.current_price_tier
      );
      this.selectedPriceIndex = tierIndex !== -1 ? tierIndex : 0;
    },
    setBasket(basket: ApiResponses.CartGetResponse) {
      this.basket = basket;
      this.updateSelectedPriceIndex();
      PagCartUtils.handleChangedItems(this.basket);
    },
    async reorder(id: string | number) {
      try {
        await request.send("post", `/cart/reorder/${id}`);
        await this.getBasket();
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
  },
});
