import { defineStore } from "pinia";
import { ApiMethods, ApiResponses, Types } from "patchandgo-ui";
import request from "@/store/request";
import utils from "@/utils";
import { useBasketStore } from "@/store/basket.store";
import router from "@/router";

export interface UserStoreState {
  isLogin: boolean;
  user: object | null;
  profile: ApiResponses.ProfileGetResponse;
  static: ApiResponses.StaticResponse;
  orders: ApiResponses.OrderWholesaleListResponse;
}

export const useUserStore = defineStore("userStore", {
  state: (): UserStoreState => {
    return {
      isLogin: false,
      user: null,
      profile: {} as ApiResponses.ProfileGetResponse,
      static: {} as ApiResponses.StaticResponse,
      orders: {} as ApiResponses.OrderWholesaleListResponse,
    };
  },
  getters: {
    getBrands(state) {
      return state.static?.brands || [];
    },
    getCatalogs(state) {
      return state.static?.catalogs || [];
    },
    getEffects(state) {
      return state.static?.effects || [];
    },
    getIngredients(state) {
      return state.static?.ingredients || [];
    },
    getSkinConditions(state) {
      return state.static?.skin_conditions || [];
    },
    getSkinTypes(state) {
      return state.static?.skin_types || [];
    },
  },
  actions: {
    async login(login: string, password: string, remember: boolean) {
      try {
        const loginData = await request.send<ApiResponses.LoginPostResponse>(
          "post",
          ApiMethods.LOGIN,
          { login, password },
          false
        );

        utils.setAuthDataInStorage(loginData, remember);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async forgotPassword(email: string) {
      try {
        await request.send("post", "/auth/recover", { email }, false);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getProfile() {
      try {
        const data = await request.send<ApiResponses.ProfileGetResponse>(
          "get",
          ApiMethods.GET_PROFILE
        );
        this.setProfileObject(data);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getStatic() {
      try {
        this.static = await request.send<ApiResponses.StaticResponse>(
          "get",
          ApiMethods.GET_STATIC
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async refreshToken() {
      try {
        const loginData = await request.send<ApiResponses.LoginPostResponse>(
          "post",
          ApiMethods.AUTH_REFRESH,
          {
            access: utils.getUserTokenFromStorage(),
            refresh: utils.getUserRefreshTokenFromStorage(),
          },
          false,
          true
        );
        utils.setAuthDataInStorage(
          loginData,
          utils.stringToBoolean(utils.getUserRememberValueFromStorage() || "0")
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async setMainAddress(id: number) {
      try {
        this.profile = await request.send<any>(
          "put",
          `/profile/shipping/main/${id}`
        );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async logout() {
      utils.removeAllTokensInStorage();
      this.user = null;
      this.profile = {} as ApiResponses.ProfileGetResponse;
      this.static = {} as ApiResponses.StaticResponse;
      this.isLogin = false;
      // @ts-ignore
      await this.$router.push({ name: "login" });
    },
    markLogin() {
      this.isLogin = true;
    },
    async addNewAddress(data: any) {
      try {
        const profileData = await request.send<any>(
          "post",
          `/profile/shipping`,
          {
            ...data,
          }
        );

        this.setProfileObject(profileData);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateAddress(data: any, id: number) {
      try {
        const profileData = await request.send<any>(
          "put",
          ApiMethods.UPDATED_PROFILE_ADDRESS(id),
          {
            ...(({ id, is_main, ...o }) => o)(data),
          }
        );

        this.setProfileObject(profileData);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async removeAddressById(id: number) {
      try {
        const data = await request.send<any>(
          "delete",
          ApiMethods.REMOVE_PROFILE_ADDRESS(id)
        );
        this.setProfileObject(data);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    getLocalShippingObjectById(id: number): Types.ProfileAddressItem | null {
      const index = this.profile.shipping_list?.findIndex(
        (item: Types.ProfileAddressItem) => item.id === id
      );

      if (index === -1) return null;

      return this.profile.shipping_list[index];
    },
    setProfileObject(data: ApiResponses.ProfileGetResponse) {
      this.profile = {
        ...data,
        shipping_list: data?.shipping_list || [],
      };
    },
    async getOrders() {
      try {
        this.orders =
          await request.send<ApiResponses.OrderWholesaleListResponse>(
            "get",
            "/order/"
          );
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async getOrderById(id: string) {
      try {
        const data = await request.send<any>("get", `/order/${id}`);

        if (data?.cart) {
          const basketStore = useBasketStore();
          basketStore.setBasket(data.cart);
        }

        return data;
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updateProfile(data: Types.ProfileContactInfo) {
      try {
        this.profile = await request.send("put", "/profile/contact", data);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
    async updatePassword(data: FormData) {
      try {
        await request.send("put", "/profile/password", data);
      } catch (err) {
        utils.handleHttpError(err as number);
      }
    },
  },
});
