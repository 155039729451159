import Cookies from "universal-cookie";
import { CookieSetOptions } from "universal-cookie/cjs/types";

const getDateWithSomeDays = (days: number): Date => {
  const date = new Date();
  const result = date.setDate(date.getDate() + days);

  return new Date(result);
};
const _AUTH_KEY = "auth";
const _REFRESH_KEY = "refresh";
const defaultParams: CookieSetOptions = {
  path: "/",
  expires: getDateWithSomeDays(30),
};
const cookies = new Cookies();

export default {
  setAuthToken(token: string, remember: boolean) {
    let params: CookieSetOptions = {};
    if (!remember) {
      params = {
        ...params,
        expires: getDateWithSomeDays(1),
      };
    }
    cookies.set(_AUTH_KEY, token, { ...defaultParams, ...params });
  },
  getAuthToken() {
    return cookies.get(_AUTH_KEY);
  },
  setRefreshToken(token: string) {
    cookies.set(_REFRESH_KEY, token, defaultParams);
  },
  getRefreshToken() {
    return cookies.get(_REFRESH_KEY);
  },
  removeAll() {
    cookies.remove(_AUTH_KEY);
    cookies.remove(_REFRESH_KEY);
  },
};
