import { ApiResponses, Types } from "patchandgo-ui";
import errorStrings from "./error_strings";
import { useUserStore } from "@/store/user.store";
import { useBasketStore } from "@/store/basket.store";
import cookie from "./cookie";

const STORAGE_USER_EXPIRY_NAME = "expiry";
const STORAGE_USER_REMEMBER_NAME = "r";

const utils = {
  setAuthDataInStorage(
    data: ApiResponses.LoginPostResponse,
    remember: boolean
  ) {
    cookie.setAuthToken(data.access_token, remember);
    cookie.setRefreshToken(data.refresh_token);
    localStorage.setItem(STORAGE_USER_EXPIRY_NAME, data.expiry);
    localStorage.setItem(
      STORAGE_USER_REMEMBER_NAME,
      utils.booleanToString(remember)
    );
  },
  getUserTokenFromStorage() {
    return cookie.getAuthToken();
  },
  getUserRefreshTokenFromStorage() {
    return cookie.getRefreshToken();
  },
  getUserExpiryTokenFromStorage() {
    return localStorage.getItem(STORAGE_USER_EXPIRY_NAME);
  },
  getUserRememberValueFromStorage() {
    return localStorage.getItem(STORAGE_USER_REMEMBER_NAME);
  },
  removeAllTokensInStorage() {
    cookie.removeAll();
    localStorage.removeItem(STORAGE_USER_EXPIRY_NAME);
    localStorage.removeItem(STORAGE_USER_REMEMBER_NAME);
  },
  handleHttpError(errCode: number) {
    throw errCode;
  },
  getHttpErrorString(errCode: number): string {
    const keyIndex = Object.keys(errorStrings).findIndex(
      (item) => item === `err_${errCode}`
    );

    return keyIndex === -1
      ? `Неизвестная ошибка: Code ${errCode}`
      : Object.values(errorStrings)[keyIndex];
  },
  getBrandFromStaticBySlug(slug: string): Types.Brands | null {
    const userStore = useUserStore();
    const brandStaticIndex = userStore.static.brands.findIndex(
      (brand: Types.Brands) => brand.slug === slug
    );
    if (brandStaticIndex === -1) return null;

    return userStore.static.brands[brandStaticIndex];
  },
  getCountInCart(id: number): number {
    const basketStore = useBasketStore();
    if (!basketStore.basket?.items) return 0;
    const exists: Types.CartItem | undefined = Object.values(
      basketStore.basket?.items
    ).find((item: Types.CartItem) => item.product.id === id);

    return exists?.quantity || 0;
  },
  booleanToString(val: boolean): string {
    return val ? "1" : "0";
  },
  stringToBoolean(val: string): boolean {
    return val === "1";
  },
};

export default utils;
