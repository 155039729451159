import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CatalogView from "@/views/CatalogView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import authMiddleware from "@/router/auth.middleware";
import authPagesMiddleware from "@/router/auth-pages.middleware";
import ProductView from "@/views/ProductView.vue";
import CartView from "@/views/CartView.vue";
import CheckoutView from "@/views/CheckoutView.vue";
import ProfileView from "@/views/auth/ProfileView.vue";
import OrderView from "@/views/OrderView.vue";
import ForgotView from "@/views/auth/ForgotView.vue";
import ContactsView from "@/views/ContactsView.vue";
import FaqView from "@/views/FaqView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/shop",
    name: "shop",
    component: CatalogView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/product/:slug",
    name: "product",
    component: ProductView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/order/:id",
    name: "order",
    component: OrderView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactsView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
    beforeEnter: [authMiddleware],
  },
  {
    path: "/account",
    name: "account",
    children: [
      {
        path: "login",
        name: "login",
        component: LoginView,
        beforeEnter: [authPagesMiddleware],
      },
      {
        path: "forgot",
        name: "forgot",
        component: ForgotView,
        beforeEnter: [authPagesMiddleware],
      },
      {
        path: "profile",
        name: "profile",
        component: ProfileView,
        beforeEnter: [authMiddleware],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: (to) => {
      return { name: "shop" };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return { top: 0 };
  },
});

export default router;
