import { defineStore } from "pinia";
import { Types } from "patchandgo-ui";
import { useUserStore } from "@/store/user.store";
import { useLoaderStore } from "@/store/loader.store";

interface ModalAddressStore {
  form: Types.AddressModalForm | null;
  modalOpen: boolean;
}

export const useModalAddressStore = defineStore("modalAddressStore", {
  state(): ModalAddressStore {
    return {
      form: null,
      modalOpen: false,
    };
  },
  actions: {
    open() {
      this.modalOpen = true;
    },
    close() {
      this.modalOpen = false;
    },
    setEmptyForm() {
      this.form = {
        fields: {
          destination: "door",
          address: "",
          insurance: false,
          warm: false,
          crate: false,
          tk: null,
        },
      };
    },
    setExistsForm(current: Types.ProfileAddressItem) {
      this.form = {
        fields: {
          ...(({ id, is_main, tk, ...o }) => o)(current),
          tk: current.tk.slug,
        },
        current,
      };
    },
    async save(data: Types.AddressModalForm) {
      const userStore = useUserStore();
      const loaderStore = useLoaderStore();
      try {
        loaderStore.setLoaderState(true);
        await userStore.addNewAddress(data.fields);
      } finally {
        loaderStore.setLoaderState(false);
        this.close();
      }
    },
    async update(data: Types.AddressModalForm) {
      if (!data.current) return;
      const userStore = useUserStore();
      const loaderStore = useLoaderStore();
      try {
        loaderStore.setLoaderState(true);
        await userStore.updateAddress(data.fields, data.current.id);
      } finally {
        loaderStore.setLoaderState(false);
        this.close();
      }
    },
  },
});
