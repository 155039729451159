import { defineStore } from "pinia";

interface UtilsStore {
  navbarHeight: number;
}

export const useUtilsStore = defineStore("utilsStore", {
  state(): UtilsStore {
    return {
      navbarHeight: 0,
    };
  },
});
