import { Types } from "patchandgo-ui";
import { defineStore } from "pinia";
import { useUserStore } from "@/store/user.store";
import { useLoaderStore } from "@/store/loader.store";

export interface ModalChangePasswordStore {
  login: string;
  modalOpen: boolean;
}

export const useModalChangePasswordStore = defineStore(
  "modalChangePasswordStore",
  {
    state(): ModalChangePasswordStore {
      return {
        login: "",
        modalOpen: false,
      };
    },
    actions: {
      open() {
        this.modalOpen = true;
      },
      close() {
        this.modalOpen = false;
      },
      setLogin(login: string) {
        this.login = login;
      },
      async save(data: Types.ProfileChangePasswordForm) {
        const userStore = useUserStore();
        const loaderStore = useLoaderStore();
        const formData = new FormData();
        formData.append("current_password", data.current_password);
        formData.append("new_password", data.new_password);
        try {
          loaderStore.setLoaderState(true);
          await userStore.updatePassword(formData);
        } finally {
          loaderStore.setLoaderState(false);
          this.close();
        }
      },
    },
  }
);
