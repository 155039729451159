import { defineStore } from "pinia";

interface OverlayStoreState {
  overlay: boolean;
}

export const useOverlayStore = defineStore("overlayStore", {
  state: (): OverlayStoreState => {
    return {
      overlay: false,
    };
  },
  actions: {
    setOverlayState(state: boolean) {
      this.overlay = state;
    },
  },
});
