import { defineStore } from "pinia";

interface LoaderStoreState {
  loader: boolean;
}

export const useLoaderStore = defineStore("loaderStore", {
  state: (): LoaderStoreState => {
    return {
      loader: false,
    };
  },
  actions: {
    setLoaderState(state: boolean) {
      this.loader = state;
    },
  },
});
