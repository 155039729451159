import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import utils from "@/utils";
import { useUserStore } from "@/store/user.store";
import { useLoaderStore } from "@/store/loader.store";
import { useBasketStore } from "@/store/basket.store";

export default async function (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore();
  const basketStore = useBasketStore();
  const loaderStore = useLoaderStore();
  const isAuthenticated = !!utils.getUserTokenFromStorage();

  if (!isAuthenticated) {
    next({ name: "login" });
  } else {
    if (!userStore.isLogin) {
      loaderStore.setLoaderState(true);
      try {
        await userStore.getProfile();
        await userStore.getStatic();
        await basketStore.getBasket();
        userStore.markLogin();
        next();
      } catch (e) {
        // logout
        next({ name: "login" });
      }

      loaderStore.setLoaderState(false);
    }
    next();
  }
}
